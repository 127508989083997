import React from "react"

// Components
import Success from "../components/SuccessPage"


const SuccessPage = () => (
  <Success 
    title="Message Sent"
    subtitle="Thank you for getting in touch"
  />
)

export default SuccessPage
